import { MainSubStructure } from '@/utils';
import { Button, Input, Modal, Radio, Space, message } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type RecommendedPositionModel from './model';

const RecommendedPositionModal = observer((props: { store?: RecommendedPositionModel }) => {
  const {
    open,
    loading,
    recommendPositionBatch,
    onClose,
    userName,
    setValue,
    onQuery,
    onReset,
    brokerName,
    cityName,
    mainSubStructureModel,
  } = props.store;
  return (
    <Modal
      loading={loading}
      open={open}
      okText={'立即推荐'}
      onOk={recommendPositionBatch}
      title={'推荐职位'}
      width={'60%'}
      onCancel={onClose}
    >
      <div>
        <Space className="flex flex-row items-center my-4">
          <div className={'flex flex-row items-center'}>
            <span className={'flex-shrink-0 mr-[8px]'}>主播昵称</span>
            <Input
              placeholder={'请输入'}
              value={userName}
              onPressEnter={() => {
                onQuery();
              }}
              onChange={(e) => {
                setValue('userName', e.target.value);
              }}
            />
          </div>
          <div className={'flex flex-row items-center'}>
            <span className={'flex-shrink-0 mr-[8px]'}>工作城市</span>
            <Input
              placeholder={'请输入'}
              value={cityName}
              onPressEnter={() => {
                onQuery();
              }}
              onChange={(e) => {
                setValue('cityName', e.target.value);
              }}
            />
          </div>
          <Space>
            <Button
              type="primary"
              onClick={onQuery}
            >
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </Space>
        </Space>
        <div className={'h-[60vh]'}>
          <MainSubStructure store={mainSubStructureModel} />
        </div>
      </div>
    </Modal>
  );
});

export { RecommendedPositionModal };
