import { Modal } from 'antd';
import DataGrid, { TextEditor } from 'pbb-data-grid';
import { useState } from 'react';

export const ImportFailList = ({
  open = false,
  rows,
  onCancel,
}: {
  open: boolean;
  rows?: any[];
  onCancel: () => void;
}) => {
  const [visible, setVisible] = useState(open);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={800}
      title="导入失败,请修改后再导入"
      footer={null}
    >
      <DataGrid
        rowHeight={40}
        enableCellScroll={true}
        rows={rows}
        columns={[
          {
            key: 'row',
            name: '表格行数',
            width: 80,
            resizable: true,
          },
          // {
          //   key: 'param',
          //   name: '字段名',
          //   resizable: true,
          //   editable: true,
          //   editor: TextEditor,
          //   editorOptions: {
          //     editOnClick: true,
          //   },
          // },
          {
            key: 'errorMsg',
            name: '错误信息',
            width: 300,
            resizable: true,
            editable: true,
            editor: TextEditor,
            editorOptions: {
              editOnClick: true,
            },
          },
        ]}
      />
    </Modal>
  );
};
