import { type BaseData, getStaticResourceUrl, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, computed, observable } from 'mobx';
import { API } from '../../api';
import type Model from '../model';

export default class ImportModel {
  @observable public loading = false;
  @observable public importModalVisible = false;
  @observable public importFailModalVisible = false;
  @observable public importFailList: any[] = [];
  @observable templateUrl = '';

  @observable public ref: FormInstance;

  @computed get getTemplate() {
    return {
      0: 'xxx',
      1: 'xxx',
      2: 'xxx',
    };
  }

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  constructor(parentStore: Model) {
    this.parentStore = parentStore;
  }

  public parentStore: Model;

  @action openImportModal = () => {
    this.importModalVisible = true;
  };

  @action onImport = async () => {
    this.loading = true;
    try {
      await this.ref.validateFields();
      const values = await this.ref.getFieldsValue();
      const fd = new FormData();
      fd.append('file', values.file[0].originFileObj);
      fd.append('type', values.type);
      const res = await request<BaseData<any[]>>({
        url: API.importJd,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: fd,
      });
      console.log(res, 'res');
      if (res?.data?.length) {
        this.loading = false;
        this.importFailList = res.data;
        this.importFailModalVisible = true;
      } else {
        message.success('导入成功');
        this.loading = false;
        this.importModalVisible = false;
        this.parentStore.mainStore.grid.gridModel.onQuery();
      }
    } catch (error) {
      console.log('error', error);
      this.loading = false;
    }
  };

  @action onCancelFailModal = () => {
    this.importFailModalVisible = false;
    this.importFailList = [];
    this.ref.resetFields();
    this.ref.setFieldsValue({
      file: null,
    });
  };

  private templateUrlMap = {
    0: getStaticResourceUrl('pc-admin/import_template/full_time.xlsx'),
    1: getStaticResourceUrl('pc-admin/import_template/long_part_time.xlsx'),
    2: getStaticResourceUrl('pc-admin/import_template/short_part_time.xlsx'),
  };

  @action onDownloadTemplate = () => {
    window.open(this.templateUrlMap[this.ref.getFieldValue('type')]);
  };

  @action onCancel = () => {
    this.importModalVisible = false;
  };
}
