import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { isArray } from 'underscore';
import { API } from '../../api';
import type Model from '../model';

export default class ApprovalModel {
  @observable public visible = false;
  @observable public ref: FormInstance;
  @observable public positionIds: (string | number | null)[];

  @observable public isHidden = true;
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
    // this.mainSubStructureModel?.gridModel?.onQuery();
  }
  public parentStore: Model;
  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };
  // 打开审核弹窗
  @action
  public onOpenApproveModal = (id) => {
    this.visible = true;
    if (isArray(id)) {
      this.positionIds = [...id];
    } else {
      this.positionIds = [id];
    }
  };
  // 关闭审核弹窗
  @action
  public onClose = () => {
    this.visible = false;
    this.visible = false;
    this.ref.setFieldsValue({
      status: '1',
      auditReason: '',
    });
    this.positionIds = null;
    this.isHidden = true;
  };

  @action
  public onSubmit = async () => {
    await this.ref.validateFields();
    const values = await this.ref.getFieldsValue();
    console.log(values, '校验通过');
    await request<BaseData<any>>({
      url: API.approvalPostion,
      method: 'POST',
      data: {
        positionIds: this.positionIds,
        ...values,
      },
    });
    message.success('操作成功');
    this.parentStore.mainStore.grid.gridModel.onQuery();
    this.onClose();
  };

  @action
  public onChange = (fields: any) => {
    const values = this.ref.getFieldsValue();
    this.isHidden = values.status !== '2';
  };
}
