import { Button, Form, Modal, Radio, Spin, Upload } from 'antd';
import { observer } from 'mobx-react';
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'underscore';
import type ImportModel from './impoortModel';

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
export const ImportFile = observer(({ store }: { store: ImportModel }) => {
  const { importModalVisible, loading, onImport, onCancel, setRef, onDownloadTemplate } = store;
  return (
    <Modal
      centered={true}
      maskClosable={false}
      onCancel={onCancel}
      onOk={onImport}
      okText="确认导入"
      title="导入"
      confirmLoading={loading}
      destroyOnClose={true}
      open={importModalVisible}
      width={400}
    >
      <Spin
        spinning={loading}
        fullscreen={true}
        tip="导入中，请勿重复提交"
      />
      <Form
        ref={setRef}
        labelCol={{ span: 6 }}
        layout="horizontal"
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          name="type"
          label="职位类型"
          initialValue={'0'}
        >
          <Radio.Group>
            <Radio value="0">全职</Radio>
            <Radio value="1">长期兼职</Radio>
            <Radio value="2">临时兼职</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="文件"
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: '请选择格式为xlsx文件',
            },
          ]}
        >
          <Upload
            accept=".xlsx"
            beforeUpload={() => false}
            maxCount={1}
          >
            <Button type="primary">选择文件</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="模板">
          <Button
            onClick={onDownloadTemplate}
            type="primary"
          >
            下载模板
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});
