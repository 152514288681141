import { Button, Form, Input, InputNumber, Modal, Space, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { EgGrid, MainSubStructure } from '../../../../utils';
import type AlternativeSliceModel from './model';

const RelevancyEmployerModal = observer(({ store }: { store: AlternativeSliceModel }) => {
  const { isOpen, onCancel, setRef, gridModel, onFinish, onReset, onRelevancy } = store;
  return (
    <Modal
      title="该公司名下有多个雇主，请选择该职位绑定的雇主信息"
      centered={true}
      open={isOpen}
      onOk={onRelevancy}
      onCancel={onCancel}
      width="60%"
      okText="关联雇主"
    >
      {/* <Form
        layout="inline"
        style={{ width: '100%' }}
        labelAlign="left"
        ref={setRef}
        onFinish={onFinish}
      >
        <Form.Item
          label="公司名称"
          name="companyName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="雇主昵称"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
            >
              查询
            </Button>
            <Button
              htmlType="button"
              onClick={onReset}
            >
              重置
            </Button>
          </Space>
        </Form.Item>
      </Form> */}
      <div style={{ height: '500px' }}>
        <EgGrid store={gridModel} />
      </div>
    </Modal>
  );
});

export { RelevancyEmployerModal };
