import { requireSource } from '@/utils';
import { QRCode } from 'antd';
import { observer } from 'mobx-react';
import type MainStore from './model';

const jobTypeEnum = {
  1: '全职',
  2: '长期兼职',
  3: '临时兼职',
};

const acceptNewbieEnum = {
  1: '接受小白',
  2: '不接受小白',
};

const isKeepOfficeEnum = {
  1: '要坐班',
  2: '不坐班',
};

const PositionCard = ({ store }: { store: MainStore }) => {
  const { rowData } = store || {};
  const bgImg = requireSource('/canvas/positionCardBg.png');
  const titleImg = requireSource('/canvas/positionCardTitle.png');

  const cityName = rowData?.companyAddress?.cityName || null;

  const jobType = jobTypeEnum[rowData?.jobType] || null;

  const liveTypeName = rowData?.liveTypeName || null;

  const acceptNewbie = acceptNewbieEnum[rowData?.acceptNewbie] || null;

  const isKeepOffice = isKeepOfficeEnum[rowData?.isKeepOffice] || null;

  const info = [cityName, jobType, liveTypeName, acceptNewbie, isKeepOffice].filter(Boolean).join(' | ');

  const salaryFormat = () => {
    if (rowData?.jobType?.toString() === '1' && rowData?.salaryStart && rowData?.salaryEnd) {
      return (
        <div>
          {rowData?.salaryStart}-{rowData?.salaryEnd}元/{rowData?.salaryMonth}月
        </div>
      );
    }
    if (['2', '3'].includes(rowData?.jobType?.toString()) && rowData?.salaryStart && rowData?.salaryEnd) {
      return (
        <div>
          {rowData?.salaryStart}-{rowData?.salaryEnd}元/时
        </div>
      );
    }
    return '';
  };

  const salary = salaryFormat();

  return (
    // <div className="fixed right-[-100%] top-[-100%] z-[-99]">
    <div
      className="fixed right-[-100%] top-[-100%] z-[-99]"
      id="positionCardElement"
    >
      <div className="bg-white">
        <div
          className="w-[375px] h-[434px] p-[32px_32px_0_32px]"
          style={{ background: `no-repeat left top / 100%  auto url(${bgImg})` }}
        >
          <img
            src={titleImg}
            alt=""
            className="w-[84px] h-[34px] object-cover mb-2"
          />
          {rowData?.name && <div className="mb-3 font-600 text-28 text-[#251F2B]">{rowData.name}</div>}

          {info && <div className="mb-1 text-12 text-[#333]">{info}</div>}

          {salary && <div className="font-500 text-24 text-[#4354F1]">{salary}</div>}

          {rowData?.positionDescribe && (
            <div className="h-[67px] mt-[60px] pb-1 text-14 text-[#655A70] line-clamp-3 leading-21 text-ellipsis">
              {rowData.positionDescribe}
            </div>
          )}
          <div className="pt-4 pb-16 text-14 text-[#232323] font-600">(扫码查看详情)</div>
        </div>
        <div className="flex items-center py-[21px] px-[32px] bg-white">
          <QRCode
            type="svg"
            bordered={false}
            size={77}
            value={store?.wxCode}
          />
          <div className="ml-4">
            <div className="mb-3 text-14 text-[#333]">长按识别二维码</div>
            <div className="text-12 text-[#999]">查看职位详情</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PositionCard);
