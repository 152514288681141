import { observer } from 'mobx-react';
import React, { Component, useMemo, useState } from 'react';
import { SearchListStructure } from '../../../utils/searchListStructure';

import { Approval } from './checkModal';
import { ImportFile } from './import/importFile';
import { ImportFailList } from './importFailList';
import Model from './model';
import PositionCard from './positionCard';
import { RecommendedPositionModal } from './recommendedPositionModel';
import { RelevancyEmployerModal } from './relevancyEmployer';
const JDLibrary = () => {
  const store = useMemo(() => new Model(), []);
  const {
    mainStore,
    importStore,
    importStore: { importFailList, importFailModalVisible, onCancelFailModal },
    relevancyEmployerStore,
    approvalStore,
    recommendedPositionModel,
  } = store;

  return (
    <div>
      <SearchListStructure store={mainStore} />
      {/* 导入弹窗 */}
      <ImportFile store={importStore} />
      {/* 导入失败 */}
      <ImportFailList
        open={importFailModalVisible}
        rows={importFailList}
        onCancel={onCancelFailModal}
      />
      {/* 关联雇主 */}
      <RelevancyEmployerModal store={relevancyEmployerStore} />
      <Approval store={approvalStore} />
      {/* 下载职位卡片 */}
      <PositionCard store={store} />
      {/* 推荐 */}
      <RecommendedPositionModal store={recommendedPositionModel} />
    </div>
  );
};

export default observer(JDLibrary);
