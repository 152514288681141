import { Button, type FormInstance, Image, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import React from 'react';
import type { BaseData } from '../../../../utils';
import { EgGridModel, MainSubStructureModel, request } from '../../../../utils';

import { API } from '../../api';
import type Model from '../model';

export default class RelevancyEmployerModel {
  @observable public employerList;

  @observable public isOpen = false;

  @observable public ref: FormInstance;

  @observable public positionIdList: string[] | null = null;

  constructor(parentStore: Model) {
    this.parentStore = parentStore;
    // this.mainSubStructureModel?.gridModel?.onQuery();
  }

  public parentStore: Model;

  public gridModel: EgGridModel = new EgGridModel({
    columns: [
      {
        key: 'name',
        name: '雇主昵称',
      },
      {
        key: 'companyName',
        name: '公司名称',
      },
      {
        key: 'butlerName',
        name: '管家姓名',
        width: 100,
      },
      {
        key: 'positionCount',
        name: '职位数',
        width: 150,
        formatter: ({ row }) => `${row.positionCount}个`,
      },
      {
        key: 'onLinePositionCount',
        name: '上线职位数',
        width: 150,
        formatter: ({ row }) => `${row.onLinePositionCount}个`,
      },
    ].map((v) => ({
      resizable: true,
      sortable: false,
      ...v,
    })),
    showRefresh: false,
    primaryKeyField: 'id',
    sortByLocal: false,
    showCheckBox: true,
    showPager: true,
    showPagination: true,
    showGridOrderNo: true,
    showSelectedTotal: false,
    getFilterParams: () => {
      return this.ref?.getFieldsValue();
    },
    api: {
      onQuery: (params) => {
        console.log(params, '查询关联雇主参数');
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        // return request<BaseData<any>>({
        //   url: API.getSliceList,
        //   method: 'POST',
        // });
        return Promise.resolve({} as any);
      },
    },
  });

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };
  // FIXME:外部是否传入职位id
  @action onOpenModal = (ids: string[]) => {
    this.isOpen = true;
    this.positionIdList = ids;
  };
  // 关闭弹窗
  @action public onCancel = () => {
    this.isOpen = false;
    this.gridModel.resetAll();
    this.gridModel.rows = [];
    this.positionIdList = null;
  };
  // 点击查询列表
  @action public onFinish = (values: any) => {
    this.gridModel?.onQuery();
  };
  // 重置查询
  @action public onReset = () => {
    this.ref?.resetFields();
  };
  // 点击关联雇主按钮
  @action public onRelevancy = async () => {
    const ids = Array.from(this.gridModel.selectedIds);
    if (!ids.length || ids.length > 1) {
      message.warning('请选择要关联的雇主且只能选择一个');
      return;
    }
    const result = await request<{ code: number; msg?: string; data: any }>({
      url: API.confirmRelevancy,
      method: 'POST',
      data: { positionIdList: this.positionIdList, userId: ids[0] },
    });
    console.log(result, '关联雇主响应结果');
    message.success('关联成功');
    this.parentStore.mainStore.grid.gridModel.onQuery();
    this.onCancel();
  };

  // 获取雇主信息
  @action
  public getSliceInfo = async (id: any) => {
    const result = await request<{ code: number; msg?: string; data: any }>({
      url: '',
      method: 'POST',
      data: { id },
    });
    console.log(result);
    // TODO: 待联调赋值detailData
    // this.detailData = result.data;
  };
}
