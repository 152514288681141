import { Form, Input, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import type React from 'react';
import { useEffect, useState } from 'react';

interface IProps {
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (values: any) => void;
  [key: string]: any;
}
const Approval = observer(({ store }: { store: IProps }) => {
  const { visible, onClose, onSubmit, setRef, onChange, isHidden } = store;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ status: '1' });
  }, [form]);
  console.log(store);
  return (
    <Modal
      title="审核结果"
      open={visible}
      width="60%"
      centered={true}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Form
        ref={setRef}
        name="dynamic_form_item"
        layout="vertical"
        onFieldsChange={onChange}
      >
        <Form.Item
          name="status"
          label="是否通过"
          layout="horizontal"
          initialValue={'1'}
          rules={[{ required: true, message: '请选择审核结果' }]}
        >
          <Radio.Group>
            <Radio value="1">通过</Radio>
            <Radio value="2">不通过</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="auditReason"
          label="未通过原因"
          dependencies={['status']}
          hidden={isHidden}
          rules={[
            {
              required: !isHidden,
            },
            // ({ getFieldValue }) => ({
            //   validator(_, value) {
            //     if (!value || getFieldValue('approval') === 'fail') {
            //       return Promise.resolve();
            //     }
            //     return Promise.reject(new Error('The new password that you entered do not match!'));
            //   },
            // }),
            { message: '请输入未通过原因' },
          ]}
        >
          <Input.TextArea
            placeholder="请输入未通过原因"
            maxLength={100}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export { Approval };
